import type { IBaseResponse } from '../factory';
import HttpFactory from '../factory';

class ChallengeModule extends HttpFactory {
	private RESOURCE = '/api/challenge';

	async getChallenges(): Promise<IBaseResponse<Array<IChallengeModel>>> {
		return await this.call<IBaseResponse<Array<IChallengeModel>>>('GET', `${this.RESOURCE}`);
	}

	async putYearly(data: object): Promise<IBaseResponse<IChallengeModel>> {
		return await this.call<IBaseResponse<IChallengeModel>>('PUT', `${this.RESOURCE}/yearly`, data);
	}

	async postAcceptGlobal(challengeId: number): Promise<IBaseResponse<IChallengeModel>> {
		return await this.call<IBaseResponse<IChallengeModel>>('POST', `${this.RESOURCE}/accept-global/${challengeId}`);
	}
}

export interface IChallengeModel {
	challengeId: number
	name: string
	description: string
	targetCount: number
	imageUrl: string
	startTime?: string
	endTime?: string
	challengeCategoryId: number
	userChallenge?: IUserChallengeModel
}

export interface IUserChallengeModel {
	startedOn: string
	finishedAt: string | null
	currentCount: number
}

export default ChallengeModule;

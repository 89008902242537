import validate from "/workspace/web/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.12.5_@unocss+reset@0.59.0_eslint@8.57.0_floating-vue@5.2.2_rollup@_ccqjkmp4hfacigpilenjz4g7vm/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/workspace/web/middleware/01.auth.global.ts";
import _02_45consent_45global from "/workspace/web/middleware/02.consent.global.ts";
import _03_45setup_45global from "/workspace/web/middleware/03.setup.global.ts";
import manifest_45route_45rule from "/workspace/web/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.12.5_@unocss+reset@0.59.0_eslint@8.57.0_floating-vue@5.2.2_rollup@_ccqjkmp4hfacigpilenjz4g7vm/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45consent_45global,
  _03_45setup_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}
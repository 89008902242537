import { type FirebaseError } from 'firebase/app';
import type { AdditionalUserInfo, UserCredential } from 'firebase/auth';
import {
	EmailAuthProvider, GoogleAuthProvider,
	applyActionCode, browserLocalPersistence, browserSessionPersistence, confirmPasswordReset, getAdditionalUserInfo, reauthenticateWithCredential, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signOut, updatePassword,
} from 'firebase/auth';

export const useFirebase = () => {
	const { $api, $firebase, $i18n } = useNuxtApp();
	const { clearUserData } = useUserData();

	const auth = $firebase.auth!;

	const { user, pending } = $firebase.useUser();

	const isAuthenticated = computed(() => {
		return user.value !== null;
	});

	async function signInWithEmailAndPasswordAsync(email: string, password: string, rememberMe: boolean): Promise<UserCredential> {
		const persistence = rememberMe
			? browserLocalPersistence
			: browserSessionPersistence;
		auth.setPersistence(persistence);
		return signInWithEmailAndPassword(auth, email, password);
	}

	async function signOutAsync(): Promise<void> {
		await $api.user.logout();
		await signOut(auth);
		clearUserData();
	}

	// https://firebase.google.com/docs/reference/js/auth#autherrorcodes
	function generateFirebaseErrorMessage(error: FirebaseError) {
		switch (error.code) {
			case 'auth/too-many-requests':
				return $i18n.t('validation.firebase.tooManyRequests');
			case 'auth/wrong-password':
				return $i18n.t('validation.firebase.wrongPassword');
			case 'auth/email-already-in-use':
				return $i18n.t('validation.firebase.emailAlreadyInUse');
			case 'auth/invalid-email':
				return $i18n.t('validation.firebase.invalidEmail');
			case 'auth/user-not-found':
				return $i18n.t('validation.firebase.userNotFound');
			case 'auth/expired-action-code':
				return $i18n.t('validation.firebase.expiredActionCode');
			case 'auth/credential-already-in-use':
				return $i18n.t('validation.firebase.credentialsAlreadyInUse');
			case 'auth/requires-recent-login':
				return $i18n.t('validation.firebase.requiresRecentLogin');
			case 'auth/user-disabled':
				return $i18n.t('validation.firebase.userDisabled');
			case 'auth/invalid-credential':
				return $i18n.t('validation.firebase.invalidCredential');
			case 'auth/invalid-action-code':
				return $i18n.t('validation.firebase.invalidActionCode');
		}
		return $i18n.t('validation.firebase.internalError');
	}

	function sendPasswordResetEmailAsync(email: string): Promise<void> {
		return sendPasswordResetEmail(auth, email);
	}

	function confirmPasswordResetAsync(password: string, oobCode: string): Promise<void> {
		return confirmPasswordReset(auth, oobCode, password);
	}

	function verifyEmailAsync(oobCode: string): Promise<void> {
		return applyActionCode(auth, oobCode);
	}

	async function updatePasswordAsync(oldPassword: string, password: string): Promise<void> {
		const curUser = auth.currentUser;
		if (curUser) {
			const credential = EmailAuthProvider.credential(
				curUser.email!,
				oldPassword,
			);

			const { user } = await reauthenticateWithCredential(auth.currentUser, credential);
			return updatePassword(user, password);
		}

		throw new Error('No user is logged in');
	}

	async function signInWithGoogleAsync(): Promise<SsoSignInResult> {
		auth.setPersistence(browserLocalPersistence);

		const provider = new GoogleAuthProvider();

		const result = await signInWithPopup(auth, provider);
		return {
			credential: result,
			additionalUserInfo: getAdditionalUserInfo(result),
		};
	}

	return {
		user,
		pending,
		isAuthenticated,
		currentUserAsync: $firebase.currentUserAsync,
		signInWithEmailAndPasswordAsync,
		signOutAsync,
		sendPasswordResetEmailAsync,
		verifyEmailAsync,
		confirmPasswordResetAsync,
		signInWithGoogleAsync,
		updatePasswordAsync,
		generateFirebaseErrorMessage,
	};
};

export interface SsoSignInResult {
	credential: UserCredential | null
	additionalUserInfo: AdditionalUserInfo | null
}

import { type FirebaseApp, initializeApp } from 'firebase/app';
import type { Auth, User } from 'firebase/auth';
import { browserLocalPersistence, getAuth } from 'firebase/auth';

const userTokenExists = (): boolean => {
	if(process.server)
		return false;
	for (let i = 0; i < localStorage.length; i++) {
		const key = localStorage.key(i);
		if (key?.startsWith('firebase:authUser:'))
			return true;
	}

	for (let i = 0; i < sessionStorage.length; i++) {
		const key = sessionStorage.key(i);
		if (key?.startsWith('firebase:authUser:'))
			return true;
	}

	return false;
};

interface FirebasePlugin {
	app: FirebaseApp
	auth: Auth
	currentUserAsync(): Promise<User | null>
	useUser(): { pending: Ref<boolean>; user: Ref<User | null> }
}

declare module '#app' {
	interface NuxtApp {
		$firebase: FirebasePlugin
	}
}

export default defineNuxtPlugin(({ $config }) => {
	const firebaseConfig = {
		apiKey: <string>$config.public.FIREBASE_API_KEY,
		authDomain: <string>$config.public.FIREBASE_AUTH_DOMAIN,
		projectId: <string>$config.public.FIREBASE_PROJECT_ID,
		storageBucket: <string>$config.public.FIREBASE_STORAGE_BUCKET,
		messagingSenderId: <string>$config.public.FIREBASE_MESSAGING_SENDER_ID,
		appId: <string>$config.public.FIREBASE_APP_ID,
	};
	const app = initializeApp(firebaseConfig);
	const auth = getAuth(app);
	auth.setPersistence(browserLocalPersistence);

	const pending = ref<boolean>(userTokenExists());
	const firebaseUser = ref<User | null>(null);

	let currentUserLoaded = false;
	let currentUser: User | null = null;
	let resolveUser: ((value: User | null) => void) | null = null;

	const module = {
		app,
		auth,
		currentUserAsync(): Promise<User | null> {
			return new Promise((resolve) => {
				// if the user is already loaded immediately resolve its value
				if (currentUserLoaded)
					resolve(currentUser);
				// if was not loaded then store the resolve callback and wait
				else
					resolveUser = resolve;
			});
		},
		useUser() {
			return {
				pending,
				user: firebaseUser,
			};
		},
	};

	const onUserUpdate = (user: User | null) => {
		pending.value = false;
		firebaseUser.value = user;

		currentUserLoaded = true;
		currentUser = user;
		if (resolveUser)
			resolveUser(user);
	};

	auth.onIdTokenChanged(onUserUpdate);
	auth.onAuthStateChanged(onUserUpdate);

	return {
		provide: {
			firebase: module,
		},
	};
});

import type { String } from 'lodash';
import type { IBaseEmptyResponse, IBaseResponse } from '../factory';
import HttpFactory from '../factory';

class LibraryModule extends HttpFactory {
	private RESOURCE = '/api/library';

	async addBookToCategory(bookId: number, categoryId: number): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/category/add/${bookId}/${categoryId}`);
	}

	async removeBookFromCategory(bookId: number, categoryId: number): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('DELETE', `${this.RESOURCE}/category/remove/${bookId}/${categoryId}`);
	}

	async addBookToLibrary(model: IAddLibrary): Promise<IBaseResponse<Number>> {
		return await this.call<IBaseResponse<Number>>('POST', `${this.RESOURCE}/add`, model);
	}

	async removeBookFromLibrary(libraryId: number): Promise<IBaseResponse<Number>> {
		return await this.call<IBaseResponse<Number>>('DELETE', `${this.RESOURCE}/${libraryId}`);
	}

	async updateBookState(model: IUpdateLibraryState): Promise<IBaseResponse<Number>> {
		return await this.call<IBaseResponse<Number>>('POST', `${this.RESOURCE}/update-state`, model);
	}

	async getCategories(): Promise<IBaseResponse<Array<ICategoryModel>>> {
		return await this.call<IBaseResponse<Array<ICategoryModel>>>('GET', `${this.RESOURCE}/category/list`);
	}

	async removeCategories(categoryId: number): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('DELETE', `${this.RESOURCE}/category/${categoryId}`);
	}

	async saveCategory(title: string, id: number | null): Promise<IBaseResponse<ICategoryModel>> {
		return await this.call<IBaseResponse<ICategoryModel>>('POST', `${this.RESOURCE}/category/save`, {
			title,
			id,
		});
	}

	async getFavorites(): Promise<IBaseResponse<Array<number>>> {
		return await this.call<IBaseResponse<Array<number>>>('GET', `${this.RESOURCE}/favorites`);
	}

	async saveFavorites(libraryIds: Array<number>): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/favorites`, libraryIds);
	}

	async getBooksInLibrary(): Promise<IBaseResponse<ILibraryModel>> {
		return await this.call<IBaseResponse<ILibraryModel>>('GET', `${this.RESOURCE}`);
	}

	async getMinimalBooksInLibrary(onlyGoogleBooks?: boolean): Promise<IBaseResponse<Array<IMinimalBookModel>>> {
		return await this.call<IBaseResponse<Array<IMinimalBookModel>>>('GET', `${this.RESOURCE}/minimal-books/${onlyGoogleBooks ?? false}`);
	}

	async getLibraryBookState(bookId: number): Promise<IBaseResponse<ILibraryBookStateModel>> {
		return await this.call<IBaseResponse<ILibraryBookStateModel>>('GET', `${this.RESOURCE}/state/${bookId}`);
	}

	async getBookReads(libraryId: number): Promise<IBaseResponse<Array<any>>> {
		return await this.call<IBaseResponse<Array<any>>>('GET', `${this.RESOURCE}/book-reads/${libraryId}`);
	}

	async saveBookReads(libraryId: number, model: object): Promise<IBaseResponse<Array<any>>> {
		return await this.call<IBaseResponse<Array<any>>>('POST', `${this.RESOURCE}/book-reads/${libraryId}`, model);
	}
}

export default LibraryModule;

interface IUpdateLibraryState {
	libraryId: number
	dnf?: boolean
	read?: number
}

interface IAddLibrary {
	bookId: number
	categoryIds: Array<Number>
	removeFromWishlist: boolean
}

export interface ILibraryBookStateModel {
	libraryId: number | null
	dnf: boolean
	currentlyReading: boolean
	reads: number
	readYears: Array<string>
	wishlist: boolean
}

export interface ILibraryModel {
	books: Array<ILibraryBookModel>
	categories: Array<ICategoryModel>
}

export interface IMinimalBookModel {
	libraryId: number
	bookId: number
	title: string
	authors: string
	identifier: string
	bookType: 'google' | 'custom' | 'unknown'
	thumbnail: string
}

export interface ILibraryBookModel {
	libraryId: number
	bookId: number
	title: string
	authors: string
	dnf: boolean
	identifier: string
	bookType: 'google' | 'custom' | 'unknown'
	reads: number
	thumbnail: string
	wishlist: boolean
	categories: Array<number>
}

export interface ICategoryModel {
	categoryId: number
	title: String
	bookCount: number
}

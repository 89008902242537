import { defineNuxtPlugin } from '#app/nuxt'
import { LazyPvAutoComplete, LazyPvCalendar, LazyPvDropdown, LazyPvMultiSelect, LazyPvTabView, LazyPvTabPanel, LazyPvConfirmPopup, LazyPvDialog, LazyPvOverlayPanel, LazyPvSidebar } from '#components'
const lazyGlobalComponents = [
  ["PvAutoComplete", LazyPvAutoComplete],
["PvCalendar", LazyPvCalendar],
["PvDropdown", LazyPvDropdown],
["PvMultiSelect", LazyPvMultiSelect],
["PvTabView", LazyPvTabView],
["PvTabPanel", LazyPvTabPanel],
["PvConfirmPopup", LazyPvConfirmPopup],
["PvDialog", LazyPvDialog],
["PvOverlayPanel", LazyPvOverlayPanel],
["PvSidebar", LazyPvSidebar],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

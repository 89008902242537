export default defineNuxtRouteMiddleware(async (to) => {
	if (process.server)
		return;

	let enable = false;
	if (to.meta.auth) {
		const { getUserDataAsync } = useUserData();
		const userData = await getUserDataAsync();

		enable = !!userData?.analyticsConsent;
	}
	else {
		const consent = useConsent();
		enable = consent.value?.analytics && consent.value?.confirmed;
	}

	useNuxtApp().$analytics.setAnalyticsConsent(enable);
});

import type { $Fetch } from 'ofetch';

class HttpFactory {
	public $fetch: $Fetch;

	constructor(fetcher: $Fetch) {
		this.$fetch = fetcher;
	}

	/**
	* method - GET, POST, PUT
	* URL
	**/
	async call<T>(method: string, url: string, data?: object, extras: RequestInit = {}): Promise<T> {
		const { $firebase } = useNuxtApp();
		const currentUser = await $firebase.currentUserAsync();

		if (currentUser)
			extras.headers = { ...(extras.headers || {}), Authorization: `Bearer ${await currentUser.getIdToken()}` };

		const $res: T = await this.$fetch(url, { method, body: data, ...extras });
		return $res;
	}
}

export interface IBaseResponse<T> extends IBaseEmptyResponse {
	payload?: T | undefined
}

export interface IBaseEmptyResponse {
	ok: boolean
	errors: Array<string>
}

export default HttpFactory;

import type { IBaseEmptyResponse, IBaseResponse } from '../factory';
import HttpFactory from '../factory';

class UserModule extends HttpFactory {
	private RESOURCE = '/api/user';

	async logout(): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/logout`, {}, { credentials: 'include' });
	}

	async getUser(): Promise<IBaseResponse<IUserDataModel>> {
		return await this.call<IBaseResponse<IUserDataModel>>('GET', `${this.RESOURCE}/`);
	}

	async deleteUser(): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('DELETE', `${this.RESOURCE}/`);
	}

	async getUsernameStatus(username: string): Promise<IBaseResponse<boolean>> {
		return await this.call<IBaseResponse<boolean>>('GET', `${this.RESOURCE}/username-status/${encodeURIComponent(username)}`);
	}

	async postRegister(data: object, recaptchaV3: string): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/register`, data, { headers: { 'recaptcha-v3-response': recaptchaV3 } });
	}

	async postContactForm(email: string, message: string, recaptchaV3: string): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/contact-form`, { email, message }, { headers: { 'recaptcha-v3-response': recaptchaV3 } });
	}

	async requestPasswordChange(email: string, recaptchaV3: string): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/request-password-change`, { email }, { headers: { 'recaptcha-v3-response': recaptchaV3 } });
	}

	async resendEmailVerification(email: string, recaptchaV3: string): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/resend-email-verification`, { email }, { headers: { 'recaptcha-v3-response': recaptchaV3 } });
	}

	async changeEmail(email: string): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/change-email`, { email });
	}

	async updateUserData(userData: object): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('PUT', `${this.RESOURCE}/user-data`, userData);
	}

	async updatePortrait(file: any): Promise<IBaseEmptyResponse> {
		const formData = new FormData();
		formData.append('file', file);

		return await this.call<IBaseEmptyResponse>('PUT', `${this.RESOURCE}/portrait`, formData);
	}

	async updatePortfolioPropertyType(type: 'background' | 'theme' | 'pictureFrame', value: number | null): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('PUT', `${this.RESOURCE}/portfolio-property-type`, {
			propertyType: type,
			value,
		});
	}

	async updatePortfolioVisibility(portfolioPublic: boolean): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('PUT', `${this.RESOURCE}/portfolio-visibility/${portfolioPublic}`);
	}

	async enableEmailMarketing(enabled: boolean): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('PUT', `${this.RESOURCE}/email-marketing/${enabled}`);
	}

	async enableAnalytics(enabled: boolean): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('PUT', `${this.RESOURCE}/analytics/${enabled}`);
	}

	async updateNameDescription(name: string | null, description: string | null): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('PUT', `${this.RESOURCE}/name-description`, {
			name,
			description,
		});
	}

	async finishRegistration(username: string, acceptTermsConditions: boolean, emailMarketing: boolean, analyticsConsent: boolean): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('PUT', `${this.RESOURCE}/finish-registration`, {
			username,
			acceptTermsConditions,
			emailMarketing,
			analyticsConsent,
		});
	}

	async getTypes(): Promise<IBaseResponse<TypesModel>> {
		return await this.call<IBaseResponse<TypesModel>>('GET', `${this.RESOURCE}/types`);
	}
}

export default UserModule;

export interface IUserDataModel {
	username: string
	setupFinished: boolean
	name: string | null
	description: string | null
	portraitFileName: string | null
	socialFacebook: string | null
	socialInstagram: string | null
	socialTwitter: string | null
	socialYoutube: string | null
	socialGoodreads: string | null
	pictureFrameType: number | null
	pictureFrameSrc: string | null
	backgroundType: number | null
	themeType: number
	layoutType: number
	publicPortfolio: boolean
	emailMarketing: boolean
	analyticsConsent: boolean
	isPatreonSupporter: boolean
}

export interface TypesModel {
	backgrounds: Array<TypeModel>
	themes: Array<TypeModel>
	pictureFrames: Array<TypeModel>
}

export interface TypeModel {
	id: number
	name: string
	src: string | null
	isPatreonSupporter: boolean
}

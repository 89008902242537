<script setup lang="ts">
interface Props {
	label?: string
	icon?: string
	iconPosition?: 'start' | 'end'
	type?: 'button' | 'submit'
	severity?: 'basic' | 'primary' | 'secondary' | 'success' | 'error'
	gradient?: boolean
	shadow?: boolean
	size?: 'xsmall' | 'small' | 'large'
	block?: boolean
	loading?: boolean
	linkTo?: string | object
	linkTarget?: string
}

const props = withDefaults(defineProps<Props>(), {
	type: 'button',
	iconPosition: 'end',
});
</script>

<template>
	<button
		:type="props.type"
		class="btn"
		:class="[{
			[`btn--${props.severity}`]: props.severity,
			'btn:gradient': props.gradient,
			'btn:shadow': props.shadow,
			'btn:xs': props.size === 'xsmall',
			'btn:sm': props.size === 'small',
			'btn:lg': props.size === 'large',
			'btn:block': props.block,
			'btn:loading': props.loading,
		}]"
		v-if="!linkTo"
	>
		<Icon :name="props.icon" v-if="props.icon && props.iconPosition === 'start'" />
		<span class="btn__text" v-if="props.label">
			{{ props.label }}
		</span>
		<Icon :name="props.icon" v-if="props.icon && props.iconPosition === 'end'" />
		<Icon class="btn__loading" name="star-full" v-if="props.loading" />
	</button>

	<NuxtLink
		class="btn"
		:class="[{
			[`btn--${props.severity}`]: props.severity,
			'btn:gradient': props.gradient,
			'btn:shadow': props.shadow,
			'btn:xs': props.size === 'xsmall',
			'btn:sm': props.size === 'small',
			'btn:lg': props.size === 'large',
			'btn:block': props.block,
			'btn:loading': props.loading,
		}]"
		:to="props.linkTo"
		:target="props.linkTarget"
		:rel="props.linkTarget ? 'noopener noreferrer' : null"
		v-else
	>
		<Icon :name="props.icon" v-if="props.icon && props.iconPosition === 'start'" />
		<span class="btn__text" v-if="props.label">
			{{ props.label }}
		</span>
		<Icon :name="props.icon" v-if="props.icon && props.iconPosition === 'end'" />
		<Icon class="btn__loading" name="star-full" v-if="props.loading" />
	</NuxtLink>
</template>

import { useStorage } from '@vueuse/core';
import type { IUserDataModel } from '@/repository/modules/user';
import { JsonSerializer } from '@/utils/jsonSerializer';

const USER_SESSION_KEY = 'user';
let resolveUserLoadedPromise: ((value: IUserDataModel | PromiseLike<IUserDataModel | null> | null) => void) | null = null;

export const useUserData = () => {
	const { $api, $firebase } = useNuxtApp();

	const loading = useState<boolean>('loading-user-data', () => false);
	const { user, pending } = $firebase.useUser();

	const data = useStorage<IUserDataModel | null>(USER_SESSION_KEY, null, sessionStorage, { serializer: new JsonSerializer() });
	const userLoadedPromise: Promise<IUserDataModel | null> = new Promise((resolve) => {
		// if data is already set resolve the promise
		if (data.value)
			resolve(data.value);
		else
			resolveUserLoadedPromise = resolve;
	});

	watch([user, pending], async () => {
		if (user.value && !pending.value && !data.value)
			refreshUserDataAsync();
	});

	async function getUserDataAsync(): Promise<IUserDataModel | null> {
		return userLoadedPromise;
	}

	async function refreshUserDataAsync() {
		// don't execute again if loading is true or user is not logged in
		if (loading.value || !user.value)
			return;
		loading.value = true;

		try {
			const { payload, ok } = await $api.user.getUser();
			if (!ok)
				throw new Error('failed fetching user data');
			data.value = payload!;
		}
		catch (err) {
			console.error(err);
		}
		finally {
			loading.value = false;
			resolveUserLoadedPromise?.(data.value);
		}
	}

	function clearUserData() {
		data.value = null;
	}

	return {
		data,
		pending: loading,
		refreshUserDataAsync,
		clearUserData,
		getUserDataAsync,
	};
};

import type { IBaseEmptyResponse, IBaseResponse } from '../factory';
import HttpFactory from '../factory';

class CurrentlyReadingModule extends HttpFactory {
	private RESOURCE = '/api/currently-reading';

	async getBooks(): Promise<IBaseResponse<Array<ICurrentlyReadingBookModel>>> {
		return await this.call<IBaseResponse<Array<ICurrentlyReadingBookModel>>>('GET', `${this.RESOURCE}`);
	}

	async addBook(libraryId: number, currentPage: number | null, currentPercentage: number | null): Promise<IBaseResponse<ICurrentlyReadingBookModel>> {
		return await this.call<IBaseResponse<ICurrentlyReadingBookModel>>('POST', `${this.RESOURCE}`, { libraryId, currentPage, currentPercentage });
	}

	async deleteBook(libraryId: number, markDnf = false): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('DELETE', `${this.RESOURCE}/${libraryId}/${markDnf || false}`);
	}

	async finishBook(libraryId: number, startedAt: string | Date | null, finishedAt : string | Date): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/finish`, {
			libraryId,
			startedAt,
			finishedAt
		});
	}

	async updateBook(libraryId: number, currentPage: number | null, currentPercentage: number | null, totalPage: number | null): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('PUT', `${this.RESOURCE}/${libraryId}`, { currentPage, totalPage, currentPercentage });
	}

	async updateSortOrder(sortOrder: Array<any>): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('PUT', `${this.RESOURCE}/sort-order`, sortOrder);
	}
}

export default CurrentlyReadingModule;

export interface ICurrentlyReadingBookModel {
	bookIdentifier: string
	libraryId: number
	title: string
	authors: string
	thumbnail: string | null
	bookLength: number | null
	originalBookLength: number | null
	currentPage: number | null
	currentPercentage: number | null
	sortOrder: number
	startedReadingOn: string | Date | null
	updatedOn: string | Date | null
	createdOn: string | Date
}

<script setup lang="ts">
const route = useRoute();
const head = useLocaleHead({
	addDirAttribute: true,
	identifierAttribute: 'id',
	addSeoAttributes: true,
});

useHead({
	titleTemplate: (titleChunk?: string) => {
		return route.meta.title ? (`${route.meta.title} | ${titleChunk}`) : (titleChunk || '');
	},
	meta: [{ property: 'og:title', content: `Readfol.io | ${route.meta.title}` }],
	link: [
		{ rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
		{ rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
		{ rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
		{ rel: 'icon', type: 'image/svg+xml', href: '/favicon.svg' },
		{ rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#FFB76B' },
		{ rel: 'stylesheet', href: '/plugins/SplittingJS/splitting.min.css' },
	],

	script: [
		// { src: '/plugins/HeadroomJS/headroomjs.min.js', defer: true },
		{ src: '/plugins/SplittingJS/splitting.min.js', defer: true },
	],
});

useSeoMeta({
	title: 'Readfol.io',
	ogTitle: 'Readfol.io',
	description: 'Elevate your reading journey with the NEXT platform for avid readers. Readfolio is your one-stop solution for book reviews, portfolios, and community-driven content. Make your reading journey matter.',
	ogDescription: 'Elevate your reading journey with the NEXT platform for avid readers. Readfolio is your one-stop solution for book reviews, portfolios, and community-driven content. Make your reading journey matter.',
	ogImage: 'https://www.readfol.io/images/og/og-index.png',
	msapplicationTileColor: '#FFB76B',
	themeColor: '#FAFAFD',
});

// Portfolio Class
if (process.client) {
	watch(() => route.name, () => {
		if (route.name === 'app.portfolio')
			document.documentElement.classList.add('html--portfolio');
		else
			document.documentElement.classList.remove('html--portfolio');
	}, { immediate: true });
};
</script>

<template>
	<Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
		<Head>
			<template v-for="link in head.link" :key="link.id">
				<Link
					:rel="link.rel"
					:href="link.href"
					:hreflang="link.hreflang"
					:id="link.id"
				/>
			</template>
			<template v-for="meta in head.meta" :key="meta.id">
				<Meta :property="meta.property" :content="meta.content" :id="meta.id" />
			</template>
		</Head>
		<Body class="body">
			<NuxtLayout>
				<NuxtPwaManifest />
				<NuxtPage />
			</NuxtLayout>
		</Body>
	</Html>
</template>

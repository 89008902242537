<script setup lang="ts">
interface Props {
	name: string | undefined
	size?: string | number
}

const props = withDefaults(defineProps<Props>(), {
	size: 24,
});

const isLoading = ref(true);

const icon = defineAsyncComponent({
	loader: async () => {
		const component = await import(`@/assets/images/icons/ico-${props.name.toLocaleLowerCase()}.svg`);
		isLoading.value = false;
		return component;
	},
});

const iconSize = computed(() => ({
	[`icon__item:${props.size}`]: true,
}));
</script>

<template>
	<span class="icon">
		<span class="icon__item" :class="iconSize">
			<component class="icon__svg" aria-hidden="true" :is="icon" />
			<span class="icon__loading" v-if="isLoading"></span>
		</span>
	</span>
</template>

import revive_payload_client_yn0lOX6mWG from "/workspace/web/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.12.5_@unocss+reset@0.59.0_eslint@8.57.0_floating-vue@5.2.2_rollup@_ccqjkmp4hfacigpilenjz4g7vm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Bsr9Twi4Ni from "/workspace/web/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.12.5_@unocss+reset@0.59.0_eslint@8.57.0_floating-vue@5.2.2_rollup@_ccqjkmp4hfacigpilenjz4g7vm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5ooBrktyGx from "/workspace/web/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.12.5_@unocss+reset@0.59.0_eslint@8.57.0_floating-vue@5.2.2_rollup@_ccqjkmp4hfacigpilenjz4g7vm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_AlAE8NMHoQ from "/workspace/web/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.1.5_@vue+compiler-core@3.4.21_nuxt@3.11.2_postcss@8._ae424tiarpuxfxlmdft52bstf4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_NpVrFcbFJh from "/workspace/web/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.12.5_@unocss+reset@0.59.0_eslint@8.57.0_floating-vue@5.2.2_rollup@_ccqjkmp4hfacigpilenjz4g7vm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_o2bibhyhfh from "/workspace/web/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.12.5_@unocss+reset@0.59.0_eslint@8.57.0_floating-vue@5.2.2_rollup@_ccqjkmp4hfacigpilenjz4g7vm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/workspace/web/.nuxt/components.plugin.mjs";
import prefetch_client_FvgDB2x738 from "/workspace/web/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.12.5_@unocss+reset@0.59.0_eslint@8.57.0_floating-vue@5.2.2_rollup@_ccqjkmp4hfacigpilenjz4g7vm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_y1twtU1sBP from "/workspace/web/node_modules/.pnpm/nuxt-gtag@2.0.5_rollup@4.14.1/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import primevue_plugin_egKpok8Auk from "/workspace/web/.nuxt/primevue-plugin.mjs";
import plugin_client_52TDWykAe0 from "/workspace/web/node_modules/.pnpm/nuxt-primevue@0.3.1_rollup@4.14.1_vue@3.4.8/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import motion_jWJGmg0OMf from "/workspace/web/node_modules/.pnpm/@vueuse+motion@2.1.0_rollup@4.14.1_vue@3.4.8/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import pwa_icons_kJnUx1Cvwx from "/workspace/web/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_rollup@4.14.1_vite@5.2.8_workbox-build@7.0.0_workbox-window@7.0.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_OY3BEYMF6d from "/workspace/web/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_rollup@4.14.1_vite@5.2.8_workbox-build@7.0.0_workbox-window@7.0.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import i18n_gmykp1Q2ud from "/workspace/web/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_rollup@4.14.1_vue@3.4.8/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_MbKK6TByIM from "/workspace/web/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.14.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_client_eokLpo7k53 from "/workspace/web/node_modules/.pnpm/@nuxtjs+color-mode@3.3.3_rollup@4.14.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_cQBUVpPrmK from "/workspace/web/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.12.5_@unocss+reset@0.59.0_eslint@8.57.0_floating-vue@5.2.2_rollup@_ccqjkmp4hfacigpilenjz4g7vm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import screenSize_6fz1MsLdCz from "/workspace/web/plugins/screenSize.ts";
import _01_firebase_client_NXDA6mUqa1 from "/workspace/web/plugins/01.firebase.client.ts";
import _02_analytics_w9hsKbV0ge from "/workspace/web/plugins/02.analytics.ts";
import _03_vue3_toastify_client_TnpqA2N9EU from "/workspace/web/plugins/03.vue3-toastify.client.ts";
import _04_api_wkwmf31gNz from "/workspace/web/plugins/04.api.ts";
import executeLoading_2TOHu9erKn from "/workspace/web/plugins/executeLoading.ts";
import scrollspy_client_FyIDBcmDrl from "/workspace/web/plugins/scrollspy.client.ts";
import vue3lottie_client_2FkeirVMHd from "/workspace/web/plugins/vue3lottie.client.ts";
export default [
  revive_payload_client_yn0lOX6mWG,
  unhead_Bsr9Twi4Ni,
  router_5ooBrktyGx,
  _0_siteConfig_AlAE8NMHoQ,
  payload_client_NpVrFcbFJh,
  check_outdated_build_client_o2bibhyhfh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_FvgDB2x738,
  plugin_client_y1twtU1sBP,
  primevue_plugin_egKpok8Auk,
  plugin_client_52TDWykAe0,
  motion_jWJGmg0OMf,
  pwa_icons_kJnUx1Cvwx,
  pwa_client_OY3BEYMF6d,
  i18n_gmykp1Q2ud,
  plugin_MbKK6TByIM,
  plugin_client_eokLpo7k53,
  chunk_reload_client_cQBUVpPrmK,
  screenSize_6fz1MsLdCz,
  _01_firebase_client_NXDA6mUqa1,
  _02_analytics_w9hsKbV0ge,
  _03_vue3_toastify_client_TnpqA2N9EU,
  _04_api_wkwmf31gNz,
  executeLoading_2TOHu9erKn,
  scrollspy_client_FyIDBcmDrl,
  vue3lottie_client_2FkeirVMHd
]
import type { IBaseEmptyResponse, IBaseResponse } from '../factory';
import HttpFactory from '../factory';

class WishlistModule extends HttpFactory {
	private RESOURCE = '/api/wishlist';

	async addToWishlist(bookId: number): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/${bookId}`);
	}

	async removeFromWishlist(bookId: number): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('DELETE', `${this.RESOURCE}/${bookId}`);
	}

	async getWishlist(): Promise<IBaseResponse<Array<IWishlistBookModel>>> {
		return await this.call<IBaseResponse<Array<IWishlistBookModel>>>('GET', `${this.RESOURCE}/books`);
	}
}

export default WishlistModule;

export interface IWishlistBookModel {
	bookId: number
	identifier: string
	title: string
	authors: string
	thumbnail?: string
	inLibrary: boolean
	bookType: 'google' | 'custom' | 'unknown'
}

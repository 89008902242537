import type { IBaseResponse } from '../factory';
import HttpFactory from '../factory';

class ExploreModule extends HttpFactory {
	private RESOURCE = '/api/explore';

	async get(): Promise<IBaseResponse<Array<IDiscoverBookModel>>> {
		return await this.call<IBaseResponse<Array<IDiscoverBookModel>>>('GET', `${this.RESOURCE}`);
	}
}

export default ExploreModule;

export interface IDiscoverBookModel {
	bookId: number
	identifier: string
	title: string
	authors: string
	thumbnail: string | null
	bookType: 'google' | 'custom' | 'unknown'
}

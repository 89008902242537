export interface IConsent {
	confirmed: boolean
	analytics: boolean
}

const getDefault = () => {
	return {
		confirmed: false,
		analytics: false,
	};
};
const expires = new Date();
expires.setFullYear(expires.getFullYear() + 1);

export const useConsent = () => useCookie<IConsent>('consent', { watch: true, default: getDefault, path: '/', expires });

import type { IBaseEmptyResponse, IBaseResponse } from '../factory';
import HttpFactory from '../factory';
import type PredefinedTemplate from '@/constants/predefinedTemplate';

class TemplateModule extends HttpFactory {
	private RESOURCE = '/api/template';

	async getTemplateList(): Promise<IBaseResponse<Array<ITemplateListItemModel>>> {
		return await this.call<IBaseResponse<Array<ITemplateListItemModel>>>('GET', `${this.RESOURCE}/list`);
	}

	async getTemplateData(templateIdentifier: string): Promise<IBaseResponse<ITemplateDataModel>> {
		return await this.call<IBaseResponse<ITemplateDataModel>>('GET', `${this.RESOURCE}/data/${templateIdentifier}`);
	}

	async putSaveDraft(data: ISaveDataModel, templateIdentifier: string): Promise<IBaseResponse<object>> {
		return await this.call<IBaseResponse<object>>('PUT', `${this.RESOURCE}/save-draft/${templateIdentifier}`, data);
	}

	async publishTemplate(templateIdentifier: string): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/publish/${templateIdentifier}`);
	}

	async cloneTemplate(name: string, templateId: number, description?: string): Promise<IBaseResponse<string>> {
		return await this.call<IBaseResponse<string>>('POST', `${this.RESOURCE}/clone`, { name, description, templateId });
	}

	async saveTemplate(templateIdentifier: string, name: string, description?: string): Promise<IBaseResponse<ITemplateListItemModel>> {
		return await this.call<IBaseResponse<ITemplateListItemModel>>('PUT', `${this.RESOURCE}/save`, { name, description, identifier: templateIdentifier });
	}

	async deleteTemplate(identifier: string): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('DELETE', `${this.RESOURCE}/${identifier}`);
	}

	// config
	async getTemplateConfig(templateId: number): Promise<IBaseResponse<IConfigModel>> {
		return await this.call<IBaseResponse<IConfigModel>>('GET', `${this.RESOURCE}/config/${templateId}`);
	}

	async saveTemplateConfig(templateId: number, config: IStoreConfigModel): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/config/${templateId}`, config);
	}

	// custom config
	async saveTemplateCustomConfig(templateId: number, field: IStoreCustomConfigModel): Promise<IBaseResponse<object>> {
		return await this.call<IBaseResponse<object>>('POST', `${this.RESOURCE}/config/custom/${templateId}`, field);
	}

	async getCustomConfig(fieldId: number): Promise<IBaseResponse<IFieldModel>> {
		return await this.call<IBaseResponse<IFieldModel>>('GET', `${this.RESOURCE}/config/custom/${fieldId}`);
	}

	async deleteCustomConfig(fieldId: number): Promise<IBaseResponse<IFieldModel>> {
		return await this.call<IBaseResponse<IFieldModel>>('DELETE', `${this.RESOURCE}/config/custom/${fieldId}`);
	}

	// save options
	async saveOption(selectId: number, value: string, optionId: number | null): Promise<IBaseResponse<number>> {
		return await this.call<IBaseResponse<number>>('PUT', `${this.RESOURCE}/option`, { selectId, value, optionId });
	}

	async deleteOption(optionId: number): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('DELETE', `${this.RESOURCE}/option/${optionId}`);
	}

	async addBookToTemplate(identifier: string, bookIdentifier: string, dateStarted: Date | string | null, dateFinished: Date | string | null): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/add-book-to-template/${identifier}`,
			{
				bookIdentifier,
				dateStarted,
				dateFinished,
			});
	}

	async getBookRating(bookIdentifier: string): Promise<IBaseResponse<IQuickRatingModel>> {
		return await this.call<IBaseResponse<IQuickRatingModel>>('GET', `${this.RESOURCE}/book-rating/${bookIdentifier}`);
	}

	async quickRate(rating: number | null, note: string | null, review: string | null, bookIdentifier: string): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/quick-rating/${bookIdentifier}`, { rating, note, review });
	}

	async updateQuickRatingPrecision(precision: RatingPrecision): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('PUT', `${this.RESOURCE}/quick-rating/precision/${precision}`);
	}

	async getQuickRatingPrecision(): Promise<IBaseResponse<RatingPrecision>> {
		return await this.call<IBaseResponse<RatingPrecision>>('GET', `${this.RESOURCE}/quick-rating/precision`);
	}
}

export default TemplateModule;

export type RatingPrecision = 'full' | 'half' | 'quarter';

interface ITemplateBaseModel {
	templateId: number
	identifier: string
	name: string
	description: string
	clonedTemplateId: PredefinedTemplate | null
	ratingsCount: number
	createdAt: Date | string
}

export interface ITemplateListItemModel extends ITemplateBaseModel {
	ratingsCount: number
}

interface ISelectOptionModel {
	optionId: number
	name: string
	isEditable: boolean
}

interface ISelectModel {
	selectId: number
	name: string
	options: Array<ISelectOptionModel>
}

export interface IFieldModel {
	categoryId: number
	fieldId: number
	name: string
	type: number
	sortOrder: number
	select?: ISelectModel
	traits?: Array<number>
}

export interface IDataPointModel {
	fieldId: number
	valueDate?: Date | null
	valueText?: string | null
	valueNumber?: number | null
	valueBoolean?: boolean | null
	valueOption?: number | null
	valueOptions?: number[] | null
}

export interface IEntryModel {
	bookType: 'google' | 'custom' | 'unknown'
	entryId: number
	bookIdentifier: string | null
	points: Array<IDataPointModel>
	bookDetails: BookDetailsModel | null
}

export interface BookDetailsModel {
	title: string
	authors: string
	thumbnail: string | null
}

export interface ITemplateDataModel {
	clonedTemplateId: PredefinedTemplate | null
	description: string
	hasUnpublishedData: boolean
	name: string
	templateId: number
	identifier: string
	fields: Array<IFieldModel>
	entries: Map<number, IEntryModel>
}

export interface ISaveDataModel {
	entries: Array<IDataRowModel>
	removedEntries: Array<number>
}

export interface IDataRowModel {
	clientIdentifier: string
	bookIdentifier: string | null
	entryId: number | null
	dataPoints: Array<IDataPointModel>
}

export interface ITemplateConfigModel {
	fieldId: number
	name: string
	enabled: boolean
	type: number
	categoryId: number
	traits?: Array<number>
}

export interface IConfigModel {
	name: string
	fields: Map<number, Array<ITemplateConfigModel>>
}

export interface IStoreConfigModel {
	fields: Array<IStoreFieldConfigModel>
	simpleRating?: IStoreSimpleRatingModel
	cawpileRating?: IStoreCawpileRatingModel
}

interface IStoreFieldConfigModel {
	fieldId: number
	enabled: boolean
}

interface IStoreSimpleRatingModel {
	enableQuarterStar: boolean
	enableHalfStar: boolean
}

interface IStoreCawpileRatingModel {
	cName?: string
	aName?: string
	wName?: string
	pName?: string
	iName?: string
	lName?: string
	eName?: string
	enableQuarterStar: boolean
	enableHalfStar: boolean
}

export interface IStoreCustomConfigModel {
	fieldId: number
	type: string
	name: string
	trait: string
	removeOptions: Array<number>
	options: Array<IStoreCustomConfigOptionModel>
}

export interface IStoreCustomConfigOptionModel {
	optionId?: number
	value: string
}

interface IQuickRatingModel {
	rating: number | null
	note: string | null
	review: string | null
	ratingsCount: number
	precision: RatingPrecision
	isOnlyQuickRated: boolean
}

import type { IBaseEmptyResponse, IBaseResponse } from '../factory';
import HttpFactory from '../factory';

class BookModule extends HttpFactory {
	private RESOURCE = '/api/book';

	async getBookDetails(id: string): Promise<IBaseResponse<IBookDetailsModel>> {
		const params = new URLSearchParams();
		if (id && id.length == 36)
			params.append('identifier', id);
		else
			params.append('googleBookId', id);

		return await this.call<IBaseResponse<IBookDetailsModel>>('GET', `${this.RESOURCE}/details?${params}`);
	}

	/* async getGoogleBooks(search: string, type: 'title' | 'author' | 'publisher' | 'isbn' | null, index: number | null, count: number | null): Promise<IBaseResponse<Array<any>>> {
		const params = new URLSearchParams();
		if (type)
			params.append('type', String(type));
		if (count)
			params.append('count', String(count));
		if (index)
			params.append('index', String(index));

		return await this.call<IBaseResponse<Array<any>>>('GET', `${this.RESOURCE}/search/${encodeURIComponent(search)}?${params}`);
	} */

	async postCustomBook(body: object): Promise<IBaseResponse<Number>> {
		return await this.call<IBaseResponse<Number>>('POST', `${this.RESOURCE}/custom/save`, body);
	}

	async getCustomBooks(): Promise<IBaseResponse<Array<object>>> {
		return await this.call<IBaseResponse<Array<object>>>('GET', `${this.RESOURCE}/custom/list`);
	}

	async deleteCustomBooks(bookId: number): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('DELETE', `${this.RESOURCE}/custom/${bookId}`);
	}

	async getBookProperties(bookIdentifier: string): Promise<IBaseResponse<IBookPropertiesModel>> {
		return await this.call<IBaseResponse<IBookPropertiesModel>>('GET', `${this.RESOURCE}/book-properties/${bookIdentifier}`);
	}
}

export default BookModule;

interface IBookPropertiesModel {
	bookType: 'google' | 'custom' | 'unknown'
	bookId: number
	title: string
	authors: string
	publisher: string
	thumbnail: string | null
	publicationYear: string
}

export interface IBookDetailsModel {
	bookId: number
	bookIdentifier: string
	googleBookData: any
	ratingStats: IRatingStatsModel | null
}

export interface IRatingStatsModel {
	rating: number | null
	simpleRating: number | null
	cawpileRating: number | null
	cRating: number | null
	aRating: number | null
	wRating: number | null
	pRating: number | null
	iRating: number | null
	lRating: number | null
	eRating: number | null
}

export default defineNuxtRouteMiddleware(async (to) => {
	if (process.server)
		return;

	if (!to.meta.auth)
		return;

	const { signOutAsync, currentUserAsync } = useFirebase();

	const currentUser = await currentUserAsync();
	const authenticated = !!currentUser;

	if (!authenticated) {
		return navigateTo({ name: 'app.login', query: { returnUrl: to.fullPath } });
	}
	else {
		if (!currentUser.emailVerified) {
			await signOutAsync();
			return navigateTo({ name: 'app.verifyEmail' });
		}
	}
});

import Vue3Toastify, { toast } from 'vue3-toastify';

declare module '#app' {
	interface NuxtApp {
		$toast: typeof toast
	}
}

export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.use(Vue3Toastify, {
		autoClose: 2400,
		dangerouslyHTMLString: true,
		icon: false,
		closeButton: false,
	});

	return {
		provide: { toast },
	};
});

import { default as about_45cawpile_45rating0Lh5g7ukKnMeta } from "/workspace/web/pages/about-cawpile-rating.vue?macro=true";
import { default as about_45simple_45ratingfdQLyxUEIAMeta } from "/workspace/web/pages/about-simple-rating.vue?macro=true";
import { default as add_45to_45library6Q2H1ao0T1Meta } from "/workspace/web/pages/book/[bookId]/add-to-library.vue?macro=true";
import { default as indexh5T9Ue8uW0Meta } from "/workspace/web/pages/book/[googleBookId]/index.vue?macro=true";
import { default as rate_45a_45bookzT9AvZdxCxMeta } from "/workspace/web/pages/book/[googleBookId]/rate-a-book.vue?macro=true";
import { default as _91googleBookId_93BY6IyPYq8TMeta } from "/workspace/web/pages/book/[googleBookId].vue?macro=true";
import { default as manage_45read_45datesiDP065Pb8jMeta } from "/workspace/web/pages/book/[libraryId]/manage-read-dates.vue?macro=true";
import { default as indexD6y0hdysvmMeta } from "/workspace/web/pages/book/index.vue?macro=true";
import { default as book0wEJIOvzghMeta } from "/workspace/web/pages/book.vue?macro=true";
import { default as contact_45usRhh73HfWbgMeta } from "/workspace/web/pages/contact-us.vue?macro=true";
import { default as faq5NXQCs1LhzMeta } from "/workspace/web/pages/faq.vue?macro=true";
import { default as firebase_45actionv0bX5a9AKLMeta } from "/workspace/web/pages/firebase-action.vue?macro=true";
import { default as forgot_45passwordoq2JlUA6yeMeta } from "/workspace/web/pages/forgot-password.vue?macro=true";
import { default as goodbyeJs7w7mmruzMeta } from "/workspace/web/pages/goodbye.vue?macro=true";
import { default as indexZ1qfB7vlFVMeta } from "/workspace/web/pages/index.vue?macro=true";
import { default as loginfHGVihhSnHMeta } from "/workspace/web/pages/login.vue?macro=true";
import { default as logoutlkV23kqWjHMeta } from "/workspace/web/pages/logout.vue?macro=true";
import { default as my_45portfolioA9vrru1GKhMeta } from "/workspace/web/pages/my-portfolio.vue?macro=true";
import { default as new_45passwordT95u3d2nAFMeta } from "/workspace/web/pages/new-password.vue?macro=true";
import { default as _91portfolioId_93glHamI2yzsMeta } from "/workspace/web/pages/portfolio/[portfolioId].vue?macro=true";
import { default as privacy_45policyJCASYTbZdjMeta } from "/workspace/web/pages/privacy-policy.vue?macro=true";
import { default as achievementsQ3uzipMyfxMeta } from "/workspace/web/pages/profile/achievements.vue?macro=true";
import { default as administrationyHJR4qx1tOMeta } from "/workspace/web/pages/profile/administration.vue?macro=true";
import { default as challengesRdmuf6fT0ZMeta } from "/workspace/web/pages/profile/challenges.vue?macro=true";
import { default as create_45custom_45bookZLilOsanyOMeta } from "/workspace/web/pages/profile/create-custom-book.vue?macro=true";
import { default as currently_45readingXEetg5N5z5Meta } from "/workspace/web/pages/profile/currently-reading.vue?macro=true";
import { default as explorekKLGHjQlcFMeta } from "/workspace/web/pages/profile/explore.vue?macro=true";
import { default as friendsn0Fi39CL2sMeta } from "/workspace/web/pages/profile/friends.vue?macro=true";
import { default as indexUiJ8wJvn8MMeta } from "/workspace/web/pages/profile/index.vue?macro=true";
import { default as manage_45categoriesb2c8ivqDKfMeta } from "/workspace/web/pages/profile/manage-categories.vue?macro=true";
import { default as manage_45custom_45bookskpKkvFuPCSMeta } from "/workspace/web/pages/profile/manage-custom-books.vue?macro=true";
import { default as manage_45portfolioOvbCWvt45KMeta } from "/workspace/web/pages/profile/manage-portfolio.vue?macro=true";
import { default as my_45libraryiD1Z0qa1ruMeta } from "/workspace/web/pages/profile/my-library.vue?macro=true";
import { default as configure2rCwS3t46HMeta } from "/workspace/web/pages/profile/rating-collection/[templateId]/configure.vue?macro=true";
import { default as index8TJH6R0QVNMeta } from "/workspace/web/pages/profile/rating-collection/[templateId]/index.vue?macro=true";
import { default as _91templateId_93Oinvi4wDgwMeta } from "/workspace/web/pages/profile/rating-collection/[templateId].vue?macro=true";
import { default as createtlykj66JHiMeta } from "/workspace/web/pages/profile/rating-collection/create.vue?macro=true";
import { default as indexamJXiTlH7ZMeta } from "/workspace/web/pages/profile/rating-collection/index.vue?macro=true";
import { default as rating_45collection7oJrkayTKsMeta } from "/workspace/web/pages/profile/rating-collection.vue?macro=true";
import { default as change_45email4Xiuu7pBzxMeta } from "/workspace/web/pages/profile/settings/change-email.vue?macro=true";
import { default as change_45passwordFsSULk3fwDMeta } from "/workspace/web/pages/profile/settings/change-password.vue?macro=true";
import { default as indexObagxYnvIYMeta } from "/workspace/web/pages/profile/settings/index.vue?macro=true";
import { default as settingsbVcoHJVB12Meta } from "/workspace/web/pages/profile/settings.vue?macro=true";
import { default as starred_45portfolios7ZDdbECXjqMeta } from "/workspace/web/pages/profile/starred-portfolios.vue?macro=true";
import { default as wishlistyka6i0Bl9XMeta } from "/workspace/web/pages/profile/wishlist.vue?macro=true";
import { default as profile1vKbjTtC4vMeta } from "/workspace/web/pages/profile.vue?macro=true";
import { default as registerUAy6fozqN0Meta } from "/workspace/web/pages/register.vue?macro=true";
import { default as searchYSve4cJZk9Meta } from "/workspace/web/pages/search.vue?macro=true";
import { default as setupkErPVe3rnhMeta } from "/workspace/web/pages/setup.vue?macro=true";
import { default as terms_45and_45conditionsP8lSoWyhAQMeta } from "/workspace/web/pages/terms-and-conditions.vue?macro=true";
import { default as verify_45email4yKDCN5CafMeta } from "/workspace/web/pages/verify-email.vue?macro=true";
export default [
  {
    name: "app.aboutCawpileRating",
    path: "/about-cawpile-rating",
    meta: about_45cawpile_45rating0Lh5g7ukKnMeta || {},
    component: () => import("/workspace/web/pages/about-cawpile-rating.vue").then(m => m.default || m)
  },
  {
    name: "app.aboutSimpleRating",
    path: "/about-simple-rating",
    meta: about_45simple_45ratingfdQLyxUEIAMeta || {},
    component: () => import("/workspace/web/pages/about-simple-rating.vue").then(m => m.default || m)
  },
  {
    name: book0wEJIOvzghMeta?.name,
    path: "/book",
    component: () => import("/workspace/web/pages/book.vue").then(m => m.default || m),
    children: [
  {
    name: "app.book.addToLibrary",
    path: ":bookId()/add-to-library",
    meta: add_45to_45library6Q2H1ao0T1Meta || {},
    component: () => import("/workspace/web/pages/book/[bookId]/add-to-library.vue").then(m => m.default || m)
  },
  {
    name: _91googleBookId_93BY6IyPYq8TMeta?.name,
    path: ":googleBookId()",
    component: () => import("/workspace/web/pages/book/[googleBookId].vue").then(m => m.default || m),
    children: [
  {
    name: "app.book.details",
    path: "",
    meta: indexh5T9Ue8uW0Meta || {},
    component: () => import("/workspace/web/pages/book/[googleBookId]/index.vue").then(m => m.default || m)
  },
  {
    name: "app.book.rateABook",
    path: "rate-a-book",
    meta: rate_45a_45bookzT9AvZdxCxMeta || {},
    component: () => import("/workspace/web/pages/book/[googleBookId]/rate-a-book.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app.book.manageReadDates",
    path: ":libraryId()/manage-read-dates",
    meta: manage_45read_45datesiDP065Pb8jMeta || {},
    component: () => import("/workspace/web/pages/book/[libraryId]/manage-read-dates.vue").then(m => m.default || m)
  },
  {
    name: "app.book",
    path: "",
    component: () => import("/workspace/web/pages/book/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app.contactUs",
    path: "/contact-us",
    meta: contact_45usRhh73HfWbgMeta || {},
    component: () => import("/workspace/web/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "app.faq",
    path: "/faq",
    meta: faq5NXQCs1LhzMeta || {},
    component: () => import("/workspace/web/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "app.entry.firebaseAction",
    path: "/firebase-action",
    meta: firebase_45actionv0bX5a9AKLMeta || {},
    component: () => import("/workspace/web/pages/firebase-action.vue").then(m => m.default || m)
  },
  {
    name: "app.forgotPassword",
    path: "/forgot-password",
    meta: forgot_45passwordoq2JlUA6yeMeta || {},
    component: () => import("/workspace/web/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "app.deleteAccount",
    path: "/goodbye",
    meta: goodbyeJs7w7mmruzMeta || {},
    component: () => import("/workspace/web/pages/goodbye.vue").then(m => m.default || m)
  },
  {
    name: "app.index",
    path: "/",
    meta: indexZ1qfB7vlFVMeta || {},
    component: () => import("/workspace/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "app.login",
    path: "/login",
    meta: loginfHGVihhSnHMeta || {},
    component: () => import("/workspace/web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "app.logout",
    path: "/logout",
    meta: logoutlkV23kqWjHMeta || {},
    component: () => import("/workspace/web/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "app.myPortfolio",
    path: "/my-portfolio",
    component: () => import("/workspace/web/pages/my-portfolio.vue").then(m => m.default || m)
  },
  {
    name: "app.newPassword",
    path: "/new-password",
    meta: new_45passwordT95u3d2nAFMeta || {},
    component: () => import("/workspace/web/pages/new-password.vue").then(m => m.default || m)
  },
  {
    name: "app.portfolio",
    path: "/portfolio/:portfolioId()",
    meta: _91portfolioId_93glHamI2yzsMeta || {},
    alias: ["/p/:portfolioId"],
    component: () => import("/workspace/web/pages/portfolio/[portfolioId].vue").then(m => m.default || m)
  },
  {
    name: "app.privacyPolicy",
    path: "/privacy-policy",
    meta: privacy_45policyJCASYTbZdjMeta || {},
    component: () => import("/workspace/web/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: profile1vKbjTtC4vMeta?.name,
    path: "/profile",
    component: () => import("/workspace/web/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "app.profile.achievements",
    path: "achievements",
    meta: achievementsQ3uzipMyfxMeta || {},
    component: () => import("/workspace/web/pages/profile/achievements.vue").then(m => m.default || m)
  },
  {
    name: "app.profile.administration",
    path: "administration",
    meta: administrationyHJR4qx1tOMeta || {},
    component: () => import("/workspace/web/pages/profile/administration.vue").then(m => m.default || m)
  },
  {
    name: "app.profile.challenges",
    path: "challenges",
    meta: challengesRdmuf6fT0ZMeta || {},
    component: () => import("/workspace/web/pages/profile/challenges.vue").then(m => m.default || m)
  },
  {
    name: "app.profile.createCustomBook",
    path: "create-custom-book",
    meta: create_45custom_45bookZLilOsanyOMeta || {},
    component: () => import("/workspace/web/pages/profile/create-custom-book.vue").then(m => m.default || m)
  },
  {
    name: "app.profile.currentlyReading",
    path: "currently-reading",
    meta: currently_45readingXEetg5N5z5Meta || {},
    component: () => import("/workspace/web/pages/profile/currently-reading.vue").then(m => m.default || m)
  },
  {
    name: "app.profile.explore",
    path: "explore",
    meta: explorekKLGHjQlcFMeta || {},
    component: () => import("/workspace/web/pages/profile/explore.vue").then(m => m.default || m)
  },
  {
    name: "app.profile.friends",
    path: "friends",
    meta: friendsn0Fi39CL2sMeta || {},
    component: () => import("/workspace/web/pages/profile/friends.vue").then(m => m.default || m)
  },
  {
    name: "app.profile",
    path: "",
    component: () => import("/workspace/web/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "app.profile.manageCategories",
    path: "manage-categories",
    meta: manage_45categoriesb2c8ivqDKfMeta || {},
    component: () => import("/workspace/web/pages/profile/manage-categories.vue").then(m => m.default || m)
  },
  {
    name: "app.profile.manageCustomBooks",
    path: "manage-custom-books",
    meta: manage_45custom_45bookskpKkvFuPCSMeta || {},
    component: () => import("/workspace/web/pages/profile/manage-custom-books.vue").then(m => m.default || m)
  },
  {
    name: "app.profile.managePortfolio",
    path: "manage-portfolio",
    meta: manage_45portfolioOvbCWvt45KMeta || {},
    component: () => import("/workspace/web/pages/profile/manage-portfolio.vue").then(m => m.default || m)
  },
  {
    name: "app.profile.myLibrary",
    path: "my-library",
    meta: my_45libraryiD1Z0qa1ruMeta || {},
    component: () => import("/workspace/web/pages/profile/my-library.vue").then(m => m.default || m)
  },
  {
    name: rating_45collection7oJrkayTKsMeta?.name,
    path: "rating-collection",
    component: () => import("/workspace/web/pages/profile/rating-collection.vue").then(m => m.default || m),
    children: [
  {
    name: _91templateId_93Oinvi4wDgwMeta?.name,
    path: ":templateId()",
    component: () => import("/workspace/web/pages/profile/rating-collection/[templateId].vue").then(m => m.default || m),
    children: [
  {
    name: "app.profile.ratingCollection.configure",
    path: "configure",
    meta: configure2rCwS3t46HMeta || {},
    component: () => import("/workspace/web/pages/profile/rating-collection/[templateId]/configure.vue").then(m => m.default || m)
  },
  {
    name: "app.profile.ratingCollection.view",
    path: "",
    meta: index8TJH6R0QVNMeta || {},
    component: () => import("/workspace/web/pages/profile/rating-collection/[templateId]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app.profile.ratingCollection.create",
    path: "create",
    meta: createtlykj66JHiMeta || {},
    component: () => import("/workspace/web/pages/profile/rating-collection/create.vue").then(m => m.default || m)
  },
  {
    name: "app.profile.ratingCollection",
    path: "",
    meta: indexamJXiTlH7ZMeta || {},
    component: () => import("/workspace/web/pages/profile/rating-collection/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: settingsbVcoHJVB12Meta?.name,
    path: "settings",
    component: () => import("/workspace/web/pages/profile/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "app.profile.settings.changeEmail",
    path: "change-email",
    meta: change_45email4Xiuu7pBzxMeta || {},
    component: () => import("/workspace/web/pages/profile/settings/change-email.vue").then(m => m.default || m)
  },
  {
    name: "app.profile.settings.changePassword",
    path: "change-password",
    meta: change_45passwordFsSULk3fwDMeta || {},
    component: () => import("/workspace/web/pages/profile/settings/change-password.vue").then(m => m.default || m)
  },
  {
    name: "app.profile.settings",
    path: "",
    meta: indexObagxYnvIYMeta || {},
    component: () => import("/workspace/web/pages/profile/settings/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app.profile.starredPortfolios",
    path: "starred-portfolios",
    meta: starred_45portfolios7ZDdbECXjqMeta || {},
    component: () => import("/workspace/web/pages/profile/starred-portfolios.vue").then(m => m.default || m)
  },
  {
    name: "app.profile.wishlist",
    path: "wishlist",
    meta: wishlistyka6i0Bl9XMeta || {},
    component: () => import("/workspace/web/pages/profile/wishlist.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app.register",
    path: "/register",
    meta: registerUAy6fozqN0Meta || {},
    component: () => import("/workspace/web/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "app.search",
    path: "/search",
    meta: searchYSve4cJZk9Meta || {},
    component: () => import("/workspace/web/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "app.setup",
    path: "/setup",
    meta: setupkErPVe3rnhMeta || {},
    component: () => import("/workspace/web/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: "app.termsAndConditions",
    path: "/terms-and-conditions",
    meta: terms_45and_45conditionsP8lSoWyhAQMeta || {},
    component: () => import("/workspace/web/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "app.verifyEmail",
    path: "/verify-email",
    meta: verify_45email4yKDCN5CafMeta || {},
    component: () => import("/workspace/web/pages/verify-email.vue").then(m => m.default || m)
  }
]
<script setup lang="ts">
const head = useLocaleHead({
	addDirAttribute: true,
	identifierAttribute: 'id',
	addSeoAttributes: true,
});

const error = useError();

useHead({
	title: 'Error | Readfol.io',
});

useSeoMeta({
	msapplicationTileColor: '#FFB76B',
	themeColor: '#FAFAFD',
});

const handleError = () => {
	clearError({
		redirect: '/',
	});
};
</script>

<template>
	<Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
		<Head>
			<template v-for="link in head.link" :key="link.id">
				<Link
					:rel="link.rel"
					:href="link.href"
					:hreflang="link.hreflang"
					:id="link.id"
				/>
			</template>
			<template v-for="meta in head.meta" :key="meta.id">
				<Meta :property="meta.property" :content="meta.content" :id="meta.id" />
			</template>
		</Head>
		<Body class="body">
			<NuxtLayout name="default">
				<NuxtPwaManifest />
				<div class="container-inner container-inner--404" v-if="error?.statusCode === 404">
					<div class="container-inner__header">
						<h1 class="contact__title fs-r:48">
							{{ $t('404.title') }}
						</h1>
						<p class="contact__subtitle">
							{{ $t('404.subtitle') }}
						</p>
					</div>
					<div class="container-inner__body">

						<div class="d:flex jc:center">
							<Button
								:label="$t('actions.returnHome')"
								severity="primary"
								gradient
								@click="handleError"
							/>
						</div>

					</div>
				</div>
				<template v-else>
					<div class="container-inner container-inner--404" v-if="$config.public.IS_DEVELOPMENT">
						<h1>Development error</h1>
						<div class="d:flex flex:col gap:8 pys:48">
							<div class="d:flex ai:center gap:8">
								<span class="fw:700">URL:</span>
								<span>{{ error?.url }}</span>
							</div>
							<div class="d:flex ai:center gap:8">
								<span class="fw:700">Status Code:</span>
								<span>{{ error?.statusCode }}</span>
							</div>
							<div class="d:flex ai:center gap:8">
								<span class="fw:700">Status Message:</span>
								<span>{{ error?.statusMessage }}</span>
							</div>
							<div class="d:flex ai:center gap:8">
								<span class="fw:700">Message:</span>
								<span>{{ error?.message }}</span>
							</div>
							<div style="word-break:break-word;" v-html="error?.stack"></div>
						</div>
					</div>
					<div class="container-inner container-inner--404" v-else>
						<div class="container-inner__header">
							<h1 class="contact__title fs-r:48">
								{{ $t('500.title') }}
							</h1>
							<p class="contact__subtitle">
								{{ $t('500.subtitle') }}
							</p>
							<p>
								{{ $t('500.content') }}
								<NuxtLink :to="{ name: 'app.contactUs' }" class="link">
									{{ $t('500.button') }}
								</NuxtLink>.
							</p>
						</div>
						<div class="container-inner__body">

							<div class="d:flex jc:center">
								<Button
									:label="$t('actions.returnHome')"
									severity="primary"
									gradient
									@click="handleError"
								/>
							</div>

						</div>
					</div>
				</template>
			</NuxtLayout>
		</Body>
	</Html>
</template>

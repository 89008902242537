declare module '#app' {
	interface NuxtApp {
		$executeLoading: typeof executeLoading
	}
}

const executeLoading = (func: Function, loading: globalThis.Ref<boolean | number | string>,
	value?: number | string,
	defaultValue: boolean | number | string = false) => {
	return async () => {
		loading.value = value ?? true;
		try {
			const res = func();
			if (res instanceof Promise)
				return await res;
			return res;
		}
		finally {
			loading.value = defaultValue;
		}
	};
};

export default defineNuxtPlugin(() => {
	return {
		provide: {
			executeLoading,
		},
	};
});

import type { IBaseResponse } from '../factory';
import HttpFactory from '../factory';
import type { TypeModel } from './user';

class PortfolioModule extends HttpFactory {
	private RESOURCE = '/api/portfolio';

	async getRelevant(count: number): Promise<IBaseResponse<Array<IRelevantPortfolioModel>>> {
		return await this.call<IBaseResponse<Array<IRelevantPortfolioModel>>>('GET', `${this.RESOURCE}/relevant/${count}`);
	}
}

export default PortfolioModule;

export interface IRelevantPortfolioModel {
	username: string
	name: string
	portraitFileName: string
	pictureFrameType: TypeModel
}

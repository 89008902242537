import { computed, reactive } from 'vue';
import { useWindowSize } from '@vueuse/core';

export function useScreenSize() {
	const { width } = useWindowSize();

	const screenSize = reactive({
		'xs': computed(() => width.value > 425),
		'sm': computed(() => width.value > 575),
		'md': computed(() => width.value > 767),
		'lg': computed(() => width.value > 991),
		'xl': computed(() => width.value > 1199),
		'2xl': computed(() => width.value > 1499),
		'3xl': computed(() => width.value > 1699),
	});

	return screenSize;
}

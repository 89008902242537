export default defineNuxtRouteMiddleware(async (to) => {
	if (process.server)
		return;
	if (to.path === '/setup')
		return;

	const { currentUserAsync } = useFirebase();

	const authenticated = !!(await currentUserAsync());

	if (authenticated) {
		const { getUserDataAsync } = useUserData();
		const userData = await getUserDataAsync();

		if (!userData?.setupFinished)
			return navigateTo({ name: 'app.setup', query: { returnUrl: to.query.returnUrl || to.fullPath } });
	}
});

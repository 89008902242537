import type { IBaseEmptyResponse, IBaseResponse } from '../factory';
import HttpFactory from '../factory';
import type EventType from '@/constants/eventType';

class ActivityModule extends HttpFactory {
	private RESOURCE = '/api/activity';

	async getActivity(cursor: string | null): Promise<IBaseResponse<ActivityModel>> {
		const params = new URLSearchParams({
			cursor: cursor ?? '',
			tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
		});
		return await this.call<IBaseResponse<ActivityModel>>('GET', `${this.RESOURCE}?${params}`);
	}

	async like(eventIdentifier: string): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/like/${eventIdentifier}`);
	}

	async unlike(eventIdentifier: string): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/unlike/${eventIdentifier}`);
	}

	async userLikesPreview(eventIdentifier: string): Promise<IBaseResponse<LikesPreviewModel>> {
		return await this.call<IBaseResponse<LikesPreviewModel>>('GET', `${this.RESOURCE}/user-likes-preview/${eventIdentifier}`);
	}
}

export default ActivityModule;

export type EventModel = ActivityChallengeModel | ActivityTemplateModel | ActivityBookModel | GroupedModel<ActivityBookModel> | GroupedModel<ActivityFriendModel>;

/*
	NewFriend = 1, -> GroupedModel<ActivityFriendModel>
	NewBookInLibrary = 2, -> GroupedModel<ActivityBookModel>
	FinishedReadingBook = 3, -> ActivityBookModel
	DnfedBook = 4, -> GroupedModel<ActivityBookModel>
	QuickRatedBook = 5, -> GroupedModel<ActivityBookModel>
	ReviewedBook = 6, -> ActivityBookModel
	PublishedRatingSpace = 7, -> ActivityTemplateModel
	FinishedChallenge = 8, -> ActivityChallengeModel
*/
export interface ActivityModel {
	days: Array<DailyEventModel>
	users: { [key: string]: ActivityUserModel }
	cursor: string | null
	moreAvailable: boolean
}

export interface ActivityUserModel {
	identifier: string
	username: string
	displayName: string | null
	portraitFileName: string | null
	pictureFrameSrc: string | null
	isYou: boolean
}

export interface ActivityFriendModel {
	username: string
	displayName: string | null
}

export interface ActivityBookModel {
	bookIdentifier: string
	title: string
	authors: string | null
	thumbnail: string | null
	data: BookEventDataModel | null
}

interface BookEventDataModel {
	rating: number | null
	review: string | null
}

export interface ActivityTemplateModel {
	name: string
}

export interface ActivityChallengeModel {
	name: string
	data: {
		bookCount: number
	}
}

export interface GroupedModel<T> {
	items: Array<T>
	count: number
}

export interface DailyEventModel {
	date: string | Date
	events: Array<EventItemModel>
}

export interface EventItemModel {
	eventIdentifier: string
	eventType: EventType
	userIdentifier: string
	data: EventModel
	likes: number
	hasUserLiked: boolean
}

export interface LikesPreviewModel {
	eventIdentifier: string
	users: LikesPreviewUserModel[]

}

export interface LikesPreviewUserModel {
	name: string | null
	username: string

}

import type { FetchOptions } from 'ofetch';
import { $fetch } from 'ofetch';
import TemplateModule from '../repository/modules/template';
import LibraryModule from '@/repository/modules/library';
import BookModule from '@/repository/modules/book';
import WishlistModule from '@/repository/modules/wishlist';
import UserModule from '@/repository/modules/user';
import ChallengeModule from '@/repository/modules/challenge';
import CurrentlyReadingModule from '@/repository/modules/currentlyReading';
import ExploreModule from '@/repository/modules/explore';
import PortfolioModule from '@/repository/modules/portfolio';
import FriendsModule from '@/repository/modules/friends';
import ActivityModule from '@/repository/modules/activity';

declare module '#app' {
	interface NuxtApp {
		$api: IApiInstance
	}
}

/** ApiInstance interface provides us with good typing */
export interface IApiInstance {
	library: LibraryModule
	explore: ExploreModule
	book: BookModule
	wishlist: WishlistModule
	template: TemplateModule
	user: UserModule
	challenge: ChallengeModule
	currentlyReading: CurrentlyReadingModule
	portfolio: PortfolioModule
	friends: FriendsModule
	activity: ActivityModule
	baseUrl: string | undefined
}

export default defineNuxtPlugin(async ({ $router }) => {
	const fetchOptions: FetchOptions = {
		mode: 'cors',
		onResponseError: (context) => {
			// redirect to login if response is 401
			if (context.response.status === 401) {
				const route = useRoute();
				$router.push({ name: 'app.login', query: { returnUrl: route.fullPath } });
			}
		},
	};

	/** create a new instance of $fetcher with custom option */
	const apiFetcher = $fetch.create(fetchOptions);

	/** an object containing all repositories we need to expose */
	const modules: IApiInstance = {
		library: new LibraryModule(apiFetcher),
		book: new BookModule(apiFetcher),
		explore: new ExploreModule(apiFetcher),
		wishlist: new WishlistModule(apiFetcher),
		template: new TemplateModule(apiFetcher),
		user: new UserModule(apiFetcher),
		challenge: new ChallengeModule(apiFetcher),
		currentlyReading: new CurrentlyReadingModule(apiFetcher),
		portfolio: new PortfolioModule(apiFetcher),
		friends: new FriendsModule(apiFetcher),
		activity: new ActivityModule(apiFetcher),
		baseUrl: fetchOptions.baseURL,
	};

	return {
		provide: {
			api: modules,
		},
	};
});

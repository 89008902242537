import { type Serializer } from '@vueuse/core'

export class JsonSerializer<T> implements Serializer<T> {
	read(value: string): T {
		try {
			if(!value) {
				return null as T;
			}
			return JSON.parse(value);
		}
		catch(err) {
			console.error(err);
		}
		return null as T;
	}
	write(value: T): string {
		if(!value) {
			return '';
		}
		return JSON.stringify(value);
	}
}
const module = {
	setAnalyticsConsent: (consent: boolean) => {
		const { $config } = useNuxtApp();

		if ($config.public.IS_DEVELOPMENT)
			return;

		if (consent) {
			const { enableAnalytics } = useGtag();
			enableAnalytics($config.public.GOOGLE_ANALYTICS_ID);

			clarity('consent');
		}
		else {
			const { disableAnalytics } = useGtag();
			disableAnalytics();

			clarity('consent', false);
		}
	},
};

export default defineNuxtPlugin(() => {
	if (process.server)
		return;

	return {
		provide: {
			analytics: module,
		},
	};
});

declare module '#app' {
	interface NuxtApp {
		$analytics: typeof module
	}
}

import type { IBaseEmptyResponse, IBaseResponse } from '../factory';
import HttpFactory from '../factory';

class FriendsModule extends HttpFactory {
	private RESOURCE = '/api/friends';

	async getFriendRequests(): Promise<IBaseResponse<IFriendModel>> {
		return await this.call<IBaseEmptyResponse>('GET', `${this.RESOURCE}/requests`);
	}

	async getFriendRequestCount(): Promise<IBaseResponse<number>> {
		return await this.call<IBaseResponse<number>>('GET', `${this.RESOURCE}/request-count`);
	}

	async getFriendsList(): Promise<IBaseResponse<IFriendModel>> {
		return await this.call<IBaseEmptyResponse>('GET', `${this.RESOURCE}/list`);
	}

	async acceptFriend(userIdentifier: string): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/accept/${userIdentifier}`);
	}

	async rejectFriend(userIdentifier: string): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/reject/${userIdentifier}`);
	}

	async addFriend(username: string): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/add/${username}`);
	}

	async removeFriend(username: string): Promise<IBaseEmptyResponse> {
		return await this.call<IBaseEmptyResponse>('POST', `${this.RESOURCE}/remove/${username}`);
	}
}

export default FriendsModule;

export interface IFriendModel {
	identifier: string
	username: string
	name: string | null
	portraitFileName: string | null
	pictureFrameSrc: string | null
	createdAt: string
}
